<template>
    <div>
        <navbar :items="navItems"/>
        <data-table :dataSource="hosts" :headers="headers">
            <template slot="actions">
              <router-link :to="{name: 'add_host'}"
                class="btn btn-outline-primary btn-icon"
              >
                <i class="icofont icofont-plus" />
              </router-link>
            </template>
        </data-table>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
import DataTable from '../../components/dataTable/local.vue'
import Navbar from '../../components/navBar.vue'
import { COMPONENT_TYPE, TEXT_TYPE, NUMERIC_TYPE, DATE_TYPE} from '../../components/dataTable/dataType'
const Action = () => import('../../components/host/host/listAction.vue')
const Client = () => import('../../components/host/host/client.vue')
const Firmware = () => import('../../components/host/host/firmware.vue')
const Modele = () => import ('../../components/host/host/modele.vue')
const Version = () => import ('../../components/host/host/version.vue')
export default {
components: {DataTable, Navbar},
    data(){
        return {
            navItems: [
                {name: 'Liste des sites'}
            ]
        }
    },
    methods: {

    },
    computed: {
        ...mapGetters({
            hosts: 'host/hosts',
            isPartenaire: 'auth/is_partenaire'
        }),
        headers(){
            return [
                {label: 'Imei', name: 'imei', type: TEXT_TYPE, sortable: true},
                {label: 'Numéro', name: 'numero', type: TEXT_TYPE, sortable: true},
                {label: 'Nombre Sortie', name: 'nbClient', type: NUMERIC_TYPE, sortable: true},
                {label: 'Modèle', name: 'model', type: COMPONENT_TYPE, component: Modele},
                {label: 'Client', type: COMPONENT_TYPE, component: Client},
                {label: 'Version', name: 'version', type: COMPONENT_TYPE, component: Version},
                {label: 'Firmware', name: 'firmware', type: COMPONENT_TYPE, component: Firmware},
                {label: 'Dernière activité', name: 'lastActivity', type: DATE_TYPE, sortable: true},
                {label: 'Action', type: COMPONENT_TYPE, component: Action}
            ]
        }
    }
}
</script>